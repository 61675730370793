import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../assets/css/style.min.css";

export default function TpLayout(props) {
  debugger;
  const { applyClass } = props;
  return (
    <Row>
      <Col
        style={{ height: "500hv" }}
        className={`${applyClass} text-center changeScreenWork`}
        md={4}
      >
        <div
          style={{
            display: "flex",
            padding: 10,
          }}
        >
          <i
            onClick={() => props.history.goBack()}
            style={{
              fontSize: 30,
              border: "1px solid black",
              padding: 10,
              borderRadius: "50%",
              cursor: "pointer",
            }}
            className="fas fa-arrow-left"
          ></i>
        </div>
        <div className={"setMargin"}>
          <div>
            <img
              src={require("../../assets/images/logo-workscaler.png")}
              alt="#lgo"
              height={60}
              width={300}
            />
          </div>
        </div>
      </Col>
      <Col className="p-5" md={8}>
        {props.children}
      </Col>
    </Row>
  );
}
