import React from "react";
import TpLayout from "./TpLayout";

export default function Policy(props) {
  debugger;
  return (
    <TpLayout {...props} applyClass={"changebackColor2"}>
      <h2>PRIVACY POLICY</h2>
      <p className="text-dark">Effective: November 20, 2020</p>
      This Privacy Policy describes the information that we collect through our
      website and how we use that information. If you have any questions about
      our Privacy Policy, please contact us support@workscaler.com. This Privacy
      Policy applies to the services and products offered by WorkScaler Inc.
      (“WorkScaler” or “We”), as well as any other applications, content or
      features that we may from time to time offer on our website and mobile
      application.
      <h3 className="mt-3 mb-3">THE TYPES OF INFORMATION THAT WE COLLECT</h3>
      We collect two types of information from visitors of our website:
      personally identifiable information and non-personally identifiable
      information. If you purchase our products or services, we request certain
      personally identifiable information from you on our order form. This
      includes contact information such as your name, email address, and
      shipping address, as well as financial information such as a credit card
      number and its expiration date. We use the personally identifiable
      information that we collect for billing purposes and to fill your orders.
      If we have trouble processing an order, we also will use this information
      to contact you. In addition, if you contact us by email through our
      website, we may keep a record of your contact information and
      correspondence. In any event, we reserve the right to use your email
      address and any other personally identifiable information that you provide
      to us to respond to you and to send you marketing materials for our own
      products and services. We will not share your information with outside
      parties who wish to market other products and services to you. If at any
      time you would like to change your personally identifiable information, or
      if you no longer wish to receive materials from us or would like your
      personal information removed from our database, please contact us at
      support@workscaler.com. Alternatively, if you receive materials from us by
      email you can make use of the "opt out" provision in our communications so
      that we know that you no longer wish to receive any materials from us.
      Like most websites, our website may incorporate “pixel tags”, “web
      beacons”, or similar tracking technologies and “cookies” that allow us to
      track the actions of visitors of our website. Pixel tags and cookies are
      used to collect non-personally identifiable information, such as the name
      of your internet service provider, the IP address of the computer you are
      using, the type of browser software and operating system that you use, the
      date and time you access our website, the website address, if any, from
      which you linked directly to our website, the website address, if any, to
      which you travel from our website, your home page customization
      preferences and other similar traffic-related information. We may
      aggregate your information with similar data collected from other visitors
      to help us improve our website and the products, services, applications,
      content and features that we provide through our website. We do not use
      non-personally identifiable information in any way to create or maintain a
      personal profile of you or collect personally identifiable information
      from you.
      <h3 className="mt-3 mb-3">HOW WE USE THE INFORMATION WE GATHER</h3>
      Generally, we will not disclose the personally identifiable information
      that we collect through your use of our website to unaffiliated third
      parties. We reserve the right, however, to provide such information to our
      employees, contractors, agents, and designees to the extent necessary to
      enable them to perform certain services for us, including order
      fulfillment and website-related services such as web hosting, to improve
      website-related services and features, to perform maintenance services,
      and to distribute advertisements and other marketing materials on our
      behalf. We also reserve the right to disclose any personally identifiable
      information that we collect to any third party if we believe that we are
      required to do so for any or all of the following reasons: (i) to comply
      with the law; (ii) to comply with legal processes or governmental
      requests; (iii) to prevent, investigate, detect, or prosecute criminal
      offenses or attacks on the technical integrity of our website or our
      network; and/or (iv) to protect the rights, property, safety and security
      of WorkScaler and its employees, our website visitors, and/or the public.
      <h3 className="mt-3 mb-3">
        WE ARE COMMITTED TO MAKING SURE YOUR INFORMATION IS PROTECTED
      </h3>
      Our physical, electronic, and procedural safeguards meet or exceed current
      federal standards regarding the protection of customer information. We
      also require contractors and outside companies who work with us to adhere
      to strict privacy standards through their contracts with us.
      Unfortunately, no security system, or system of transmitting data over the
      Internet, can be guaranteed to be 100% secure. Consequently, while we use
      commercially reasonable efforts to protect your personally identifiable
      information, we cannot guarantee the security of our servers, the means by
      which information is transmitted between your computer and our servers, or
      any information provided to us or to any third party through or in
      connection with the website. Any information that you provide to us is
      done so entirely at your own risk.
      <h3 className="mt-3 mb-3">
        WE RESERVE THE RIGHT TO UPDATE AND REVISE THIS PRIVACY POLICY AT ANY
        TIME
      </h3>
      From time to time we review this Privacy Policy to ensure that it complies
      with applicable law. Consequently, we reserve the right to update and
      revise this Privacy Policy at any time. You will know if this Privacy
      Policy has been revised since your last visit to the website by referring
      to the "Effective Date of Current Policy" date at the top of this page.
      Your use of our website constitutes your acceptance of the terms of the
      Privacy Policy as amended or revised by us from time to time, and you
      should therefore review this Privacy Policy regularly to ensure that you
      are aware of its terms.
      <h3 className="mt-3 mb-3">
        IN THE EVENT WE ENGAGE IN A BUSINESS COMBINATION, WE RESERVE THE RIGHT
        TO TRANSFER ANY INFORMATION THAT WE COLLECT THROUGH OUR WEBSITE
      </h3>
      If we sell all or any part of our business or sell or transfer all or a
      material part of our assets or are otherwise involved in a merger or other
      transfer or disposition of any part our business, we reserve the right to
      access, transfer or disclose any and all information that we collect from
      our website’s visitors, or that we otherwise collect in connection with
      the website, to the party or parties involved in the transaction as part
      of that transaction. OUR WEBSITE MAY CONTAIN LINKS TO OTHER WEBSITES
      Please be aware that we are not and cannot be responsible for the privacy
      practices of any websites that you access through our website and that
      this Privacy Policy applies solely to the information which we collect
      through our website. We encourage you to ensure that you read the privacy
      statements of all the destination websites which you visit.
    </TpLayout>
  );
}
